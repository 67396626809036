import {GridType} from '../../../../types/galleryTypes';
import {useMemo} from 'react';
import {useStyles} from '@wix/tpa-settings/react';
import {useStylesParams} from '../../../../stylesParamsContext';
import {Experiments, SliderArrowsPlacement} from '../../../../constants';
import {useGlobals} from '../../../../globalPropsContext';
import {useExperiments} from '@wix/yoshi-flow-editor';

// eslint-disable-next-line sonarjs/cognitive-complexity
export const useSliderConfiguration = (sliderWidth: number) => {
  const styles = useStyles();
  const stylesParams = useStylesParams();
  const {totalProducts} = useGlobals();
  const {experiments} = useExperiments();

  const enableSliderTeaser = experiments.enabled(Experiments.EnableSliderTeaserViewer);
  const sliderGalleryInfiniteLoopToggleViewer = experiments.enabled(Experiments.SliderGalleryInfiniteLoopToggleViewer);

  const autoGrid = styles.get(stylesParams.gallery_gridType) === GridType.AUTO;
  const minProductWidth = styles.get(stylesParams.gallery_productSize);
  const galleryColumns = styles.get(stylesParams.galleryColumns);
  const shouldShowTeaser = styles.get(stylesParams.gallery_sliderShowTeaser) && totalProducts > galleryColumns;
  const arrowsInsideSlider = styles.get(stylesParams.gallery_sliderArrowsPlacement) === SliderArrowsPlacement.Inside;

  const slidesOffset = arrowsInsideSlider && shouldShowTeaser ? styles.get(stylesParams.gallery_sliderTeaserOffset) : 0;

  const spaceBetween = autoGrid
    ? styles.get(stylesParams.gallery_gapSizeColumn)
    : styles.get(stylesParams.galleryMarginColumn) * 2;

  const calculateResponsiveSlidesCount = () => {
    const availableSliderSpace = sliderWidth + spaceBetween;
    const slideSpace = minProductWidth + spaceBetween;
    const teaserSpace = shouldShowTeaser ? minProductWidth * 0.7 : 0;

    const minSpaceForAllSlides = totalProducts * slideSpace;
    if (availableSliderSpace + teaserSpace > minSpaceForAllSlides) {
      return availableSliderSpace / slideSpace;
    }

    const slidesCountWithTeaser = Math.floor((availableSliderSpace + teaserSpace) / slideSpace);
    const slideWidth = (availableSliderSpace + teaserSpace) / slidesCountWithTeaser;
    return availableSliderSpace / slideWidth;
  };

  const slidesCount = useMemo(() => {
    if (!autoGrid) {
      return shouldShowTeaser ? galleryColumns + 0.4 : galleryColumns;
    }

    if (!sliderWidth) {
      return;
    }

    const responsiveSlidesCount = calculateResponsiveSlidesCount();
    return Math.max(Math.min(responsiveSlidesCount, totalProducts), 1);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [autoGrid, shouldShowTeaser, sliderWidth, minProductWidth, slidesOffset, spaceBetween, galleryColumns]);

  const getShouldLoop = () => {
    const isLoopingEnabled = sliderGalleryInfiniteLoopToggleViewer
      ? styles.get(stylesParams.gallery_sliderInfiniteLoop)
      : true;

    if (!isLoopingEnabled) {
      return false;
    }

    const isResponsive = styles.get(stylesParams.responsive);
    if (isResponsive) {
      return isLoopingEnabled;
    }

    return enableSliderTeaser && shouldShowTeaser ? totalProducts > slidesCount + 1 : isLoopingEnabled;
  };

  const shouldLoop = getShouldLoop();

  return {slidesCount, spaceBetween, slidesOffset, shouldLoop};
};
